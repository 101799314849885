<template>
  <div class="choiceData">
    <el-dialog
      class="choiceDataCon"
      :visible.sync="isDialog"
      append-to-body
      :width="width + '%'"
      :before-close="cancellationFn"
    >
      <div slot="title" style="color: #409eff">历史单位</div>
      <div class="selected-list">
        <div class="mainList">
          <div class="mainList_operation_search">
            <el-input
              class="mainList_operation_search_Name"
              placeholder="开户行"
              clearable
              v-model="parameter.openingBank"
            ></el-input>
            <el-input
              class="mainList_operation_search_Name"
              placeholder="收款单位"
              clearable
              v-model="parameter.collectionUnit"
            ></el-input>

            <el-button type="primary" plain icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
          <div class="mainList_content">
            <div class="mainList_content_sub">
              <el-table
                :border="true"
                :data="partnerList"
                tooltip-effect="dark"
                height="string"
                ref="selectTable"
                v-loading="loading"
              >
                <el-table-column
                  align="center"
                  prop="openingBank"
                  label="开户行"
                  min-width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="bankAccount"
                  label="银行号账号"
                  min-width="150"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="collectionUnit"
                  label="收款单位"
                  min-width="280"
                >
                </el-table-column>
                <el-table-column label="操作" width="180" align="center">
                  <template slot-scope="scope">
                    <div style="display: flex; justify-content: space-around">
                      <el-button type="primary" size="mini" @click="clickFn(scope.row)"
                        >选择</el-button
                      >

                      <el-button
                        slot="reference"
                        type="danger"
                        @click="deleteData(scope.row)"
                        size="mini"
                        >删除</el-button
                      >
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="pageChangeHandler"
                :current-page="parameter.pageNow"
                :page-size="parameter.pageSize"
                prev-text="上一页"
                next-text="下一页"
                layout="total, prev, pager, next, slot, jumper"
                :total="parameter.total"
              >
                <span class="el-pagination__jump e_a_pagination">
                  <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
                  <span style="padding-top: 1px">条/页</span>
                </span>
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <EditDialog :isShow.sync="isDeletDialog" :isReturn="true" @closeFn="cancel">
      <template v-slot:title>
        <span style="color: #409eff">是否删除该历史单位信息</span>
      </template>
      <template v-slot:content>
        <el-table border :data="deletLisr" tooltip-effect="dark" height="string">
          <el-table-column
            align="center"
            prop="openingBank"
            label="开户行"
            min-width="150"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="bankAccount"
            label="银行号账号"
            min-width="150"
          ></el-table-column>
          <el-table-column align="center" prop="collectionUnit" label="收款单位" min-width="280">
          </el-table-column>
        </el-table>
      </template>
      <template v-slot:footer>
        <el-button type="danger" @click="deletePartner">确定删除</el-button>
        <el-button type="warning" plain @click="cancel">取消</el-button>
      </template>
    </EditDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 60,
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      pageSize: 0,
      parameter: {
        collectionUnit: null, //收款单位
        openingBank: null, //开户行
        pageNow: 1,
        total: 1,
        pageSize: 20,
      },
      partnerList: [], //付款单位数组
      isDeletDialog: false, //删除弹窗
      deletLisr: [], //删除数组
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        this.isDialog = newVal
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      selectDeptList: state => state.business.selectDeptList,
    }),
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return 0
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
  },
  mounted() {},
  methods: {
    handleReset() {
      this.parameter.collectionUnit = null
      this.parameter.openingBank = null
      this.parameter.pageNow = 1
      this.getData()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    getData() {
      this.loading = true
      this.$api.memberApplication
        .selectList(this.parameter)
        .then(res => {
          this.partnerList = []
          if (res.data) {
            this.partnerList = res.data.records
            this.parameter.total = res.data.total
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    saveFn() {
      this.loading = true
      this.$emit('saveFn', this.saveArr)
      this.$emit('update:isShow', false)
      this.loading = false
    },
    cancellationFn() {
      this.loading = true
      this.$emit('update:isShow', false)
      this.loading = false
    },

    clickFn(row) {
      this.$emit('saveFn', row)
      this.isDialog = false
    },
    //打开删除弹窗
    deleteData(row) {
      this.deletLisr = []
      this.deletLisr = [{ ...row }]
      this.isDeletDialog = true
    },
    // 关闭弹窗
    cancel() {
      this.deletLisr = []
      this.isDeletDialog = false
    },
    //二次确认删除历史单位记录
    deletePartner() {
      let id = this.deletLisr[0].id
      this.$api.memberApplication.deleteMemberApplication(id).then(res => {
        if (res.code) {
          this.cancel()
          this.getData()
          this.$message.success('删除成功！')
        }
      })
    },

    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.el-table-column--selection {
  .is-disabled {
    .el-checkbox__inner::before {
      content: '✓'; /* 使用 Unicode 打勾字符 */
      font-size: 12px; /* 自定义字体大小 */
      position: absolute;
      color: #c0c4cc;
      left: 2px;
      top: 0;
    }
  }
}

.mainList_operation_search_Name {
  width: 200px;
  margin: 0 10px 10px;
}
.mainList_operation_search {
  /deep/.el-input__validateIcon {
    display: none !important;
  }
}
</style>
